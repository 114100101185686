<template>
  <div class="book-detail">
    <p class="title">服务质量FMEA差距模型及应用</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书介绍的是一种服务质量分析的具体方法，帮助服务型企业策划服务质量，以服务在第一次做好为目的。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >工业发展到今天，只要提到产品设计、可靠性、缺陷预防就会想到一种有效的应用工具失效模式和后果分析，它被称为FMEA（FailureModeandEffectsAnalysis）。FMEA是在20世纪50年代开始被应用到航天领域的，它颠覆了产品设计的传统模式，是一种创举。其原理是，在产品设计时首先设想产品有哪些潜在故障，不管发生过还是没有发生过都尽可能地列出来，并对其后果进行分析，然后针对危害大风险高的故障通过设计避免其发生，这样就能基本上做到80％的问题在设计阶段得到解决。
        由于FMEA成效显著，而今不仅在军工、汽车、医疗这些可靠性要求高的产品上得到广泛应用，而且也普遍地应用民用产品了，它已成为一种非常成熟的工具，有关的书籍也不少。正因为此，不少领域也在移植它的原理，例如，安全管理、环境管理、信息管理、设备管理等。
      </span>
      <br />
      <br />
      <span>
        本书作者通过具有特色的服务蓝图展示了服务界面、服务功能以及服务过程的所有活动。从理论上讲每一个活动都存在失效模式，在服务蓝图的基础上所开发的服务FMEA就能很好地解决日常服务中的很多漏洞。开发服务FMEA实质上是开发零缺陷的服务，是开发有效的控制手段，是开发和完善服务特性的，那时你就会发现一次性
        做好服务的秘密了。这正是本书价值所在。
        所以，作为防错手段，服务FMEA应成为服务设计的重要组成部分。服务FMEA是方法论，本书的很多方法我们己经做过多次尝试，经过验证是可行的，能够指导服务提供商的实战操练。相信本书会对银行、保险、电信、运输、物流、美容等高风险服务行业以及层出不穷的各类新兴服务业有很大的帮助。
      </span>
      <br />
      <br />
      <div class="divp">
        <span>本书分为10个章节：</span>
        <p><span>第一章</span><span>服务导论</span></p>
        <p><span>第二章</span><span>顾客不满意带来的经营风险</span></p>
        <p><span>第三章</span><span>FMEA基础</span></p>
        <p><span>第四章</span><span>服务质量FMEA差距模型</span></p>
        <p><span>第五章</span><span>FMEA实施前的准备工作</span></p>
        <p><span>第六章</span><span>服务FMEA的实施步骤</span></p>
        <p><span>第七章</span><span>FMEA在物业服务中的应用</span></p>
        <p><span>第八章</span><span>FMEA在保险服务中的应用</span></p>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>